import React, { MouseEvent, RefObject } from 'react';
import theme from '@buoyhealth/common.buoy-theme';
import NextLink from 'next/link';
import cx from 'classnames';

import { AnchorLabel, Base } from 'styled/components/base';
import BuoyChatIcon from 'styled/components/svg/BuoyChatIcon';
import { Anchor, AnchorInner } from './style';
import sendEventToGoogleTagManager from 'utils/sendEventToGoogleTagManager';
import withPolarisLinkInterceptor from '../Polaris/withPolarisLinkInterceptor';

interface PassedProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  ariaLabel: string;
  containerClassName?: string;
  'data-testid'?: string;
  disabled?: boolean;
  elemRef?: RefObject<HTMLAnchorElement | typeof NextLink | HTMLButtonElement>;
  inline?: boolean;
  label?: string | React.ReactNode;
  bypassNextLink?: boolean;
  openInCurrentTab?: boolean;
  property?: string;
  to?: string;
  typeOf?: string;
  variant?:
    | 'primary'
    | 'primary-lg'
    | 'primary-md'
    | 'primary-md-white'
    | 'primary-lg-with-logo'
    | 'tag'
    | 'nav-menu'
    | 'symptom-checker-white'
    | 'symptom-checker-blue'
    | 'notification-bar-close'
    | 'underline-white'
    | 'underline-white-xs'
    | 'underline-black'
    | 'underline-blue'
    | 'underline-blue-xs'
    | 'underline-blue-xs-sm-secondary'
    | 'underline-black-xs'
    | 'hover-underline-blue'
    | 'hover-underline-white'
    | 'hover-underline-black'
    | 'hover-underline-gray'
    | 'hero'
    | 'nav-tab'
    | 'nav-tab-with-border'
    | 'nav-tab-with-border-active'
    | 'nav-tab-sections-menu-anchor'
    | 'rectangle-blue'
    | 'rectangle-white'
    | 'skip-link';
  wrap?: boolean;
  gtmTrackerEventName?: string;
  polarisTrackingLocation?: string;
}

export type Props = PassedProps;

const Link = ({
  ariaLabel,
  children,
  className,
  containerClassName,
  disabled,
  elemRef,
  id,
  inline,
  label,
  // Defaulting this to true to fix ad behavior when client-side navigating
  bypassNextLink = true,
  onFocus,
  onKeyDown,
  onMouseEnter,
  onMouseLeave,
  openInCurrentTab,
  property,
  rel,
  style,
  tabIndex,
  to,
  typeOf,
  variant,
  wrap,
  ...rest
}: Props) => {
  const handleOnClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (rest.gtmTrackerEventName) {
      sendEventToGoogleTagManager({
        event: rest.gtmTrackerEventName,
      });
    }

    rest.onClick?.(e);
  };

  const isInternalLink = to?.includes('buoyhealth.com') || to?.startsWith('/');

  const openInNewTab =
    openInCurrentTab != null ? !openInCurrentTab : !isInternalLink;

  const openInNewTabProps = openInNewTab
    ? {
        rel: `nooreferrer noopener ${rel || ''}`.trim(),
        target: '_blank',
      }
    : {};

  const variantHasChatIcon =
    variant &&
    [
      'symptom-checker-white',
      'symptom-checker-blue',
      'primary-lg-with-logo',
    ].includes(variant);

  const inner = (
    <Anchor
      data-testid={rest['data-testid'] || 'link'}
      as={to ? 'a' : 'span'}
      id={id}
      className={cx('Link__container', containerClassName)}
      variant={variant}
      style={style}
      onClick={handleOnClick}
      rel={rel}
      onFocus={onFocus}
      disabled={disabled}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      aria-label={ariaLabel}
      ref={elemRef as RefObject<HTMLAnchorElement>}
      tabIndex={tabIndex}
      property={property || undefined}
      typeof={typeOf || undefined}
      // @ts-expect-error this link renders as a span if `to` is missing to prevent dom validation issues
      href={to && bypassNextLink ? to : undefined}
      {...openInNewTabProps}
    >
      <AnchorInner
        as="span"
        className={cx('Link', className)}
        wrap={wrap}
        variant={variant}
      >
        {!!children && !label ? (
          children
        ) : (
          <>
            {variantHasChatIcon && (
              <Base
                as={BuoyChatIcon}
                primaryColor={
                  variant === 'symptom-checker-white'
                    ? theme.palette.primary.main
                    : theme.palette.common.white
                }
                secondaryColor={
                  variant === 'symptom-checker-white'
                    ? theme.palette.common.white
                    : theme.palette.primary.main
                }
                width={['1.25rem', '1.25rem', '1.5rem']}
                mr={variant === 'primary-lg-with-logo' ? 1.5 : 0.5}
                alignSelf="center"
              />
            )}
            <span
              className={cx({
                inline: inline,
              })}
            >
              <AnchorLabel className="Link__label">{label}</AnchorLabel>
            </span>
          </>
        )}
      </AnchorInner>
    </Anchor>
  );

  if (!to || bypassNextLink) {
    return inner;
  }

  return (
    <NextLink
      href={to}
      passHref={true}
      prefetch={false}
      scroll={true}
      legacyBehavior
    >
      {inner}
    </NextLink>
  );
};

export default withPolarisLinkInterceptor(Link);
